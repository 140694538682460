$primary-blue: #2a8fc6;
$hover-blue: #265c79;
$input-height: 25px;
$font-family: 'Barlow', 'Roboto', 'Helvetica', sans-serif;

body,
html {
  font-weight: 400;
  margin: 0;
}

html {
  display: flex;
}

body {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  font-family: $font-family;
  font-style: normal;
  background-color: #dae1e8;
}

a {
  font-style: normal;
  text-decoration: none;
  color: $primary-blue;
  font-weight: 500;

  &:hover {
    color: $hover-blue;
    text-decoration: underline;
  }
}

.login-panel {
  margin: 100px auto;
  width: calc(100% - 20px);
  border-radius: 10px;
  background-color: white;
  box-shadow: none;
  padding: 4em 0 3em 0;

  @media screen and (min-width: 768px) {
    margin: 150px auto 0 auto;
    width: 550px;
  }
}

form {
  padding: 1em 0;
  text-align: left;
  margin: 0 auto;
  width: 75%;

  @media screen and (min-width: 768px) {
    width: 45%;
  }
}

.login-logo {
  margin: 0 0 1em 0;

  img {
    width: 205px;
  }
}

.mui-textfield {
  margin-bottom: 8px;

  & > input {
    border-color: #adb6bb;
    height: $input-height;
    color: #000000;
    font-size: 15px;

    &:focus {
      border-color: $primary-blue;
      height: calc($input-height + 1px);
      border-width: 2px;

      & ~ label {
        color: $primary-blue;
      }
    }

    & > label {
      color: #adb6bb;
    }
  }
}

.mui-btn {
  border-radius: 20px;
  width: 100%;
  background-color: $primary-blue;
  text-transform: none;

  &:active,
  &:focus,
  &:hover {
    background-color: $hover-blue;
  }
}

.login-form-link-list {
  margin: 0;
  padding: 0;
  font-size: 12px;
  display: flex;
  justify-content: space-between;

  .login-form-link {
    list-style-type: none;

    .login-form-link-icon {
      padding-right: 0.5em;
    }
  }
}

.login-footer {
  margin-top: 2em;

  a {
    padding-left: 0.5em;
  }
}

.mui-row.alert-row {
  width: 85%;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    width: 60%;
  }
}

.logout-content {
  text-align: center;
}

.login-alerts {
  display: block;
  background-color: #adb6bb1c;
  border-radius: 4px;
  border-left: 8px;
  border-left-color: #af0837;
  border-left-style: solid;
  padding: 8px;

  &.hidden {
    display: none;
  }

  .alert-text {
    display: block;
    color: #0d2033;
    margin: 0;
    font-size: 12px;

    &.hidden {
      display: none;
    }
  }

  .alert-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #af0837;
    margin: 0 0 0.5em 0;
  }
}
